<template>
  <container>
    <!-- TODO: Change Head with new design -->
    <PartHead />

    <main class="mt-20 lg:mt-0 bg-surface-base">
      <slot />
    </main>
    <!-- TODO: Change footer with new design -->
    <PartFooter />
  </container>
</template>
<script setup>
import { computed } from "vue";

const route = useRoute();
const config = useRuntimeConfig();

const canonicalLink = computed(() => {
  const baseUrl =
    config.public.env === "production"
      ? "https://www.getheltia.com"
      : "https://www.staging.getheltia.com";

  const url = new URL(`${baseUrl}${route.path}`);
  if (route.query["page"] === "1") {
    delete route.query["page"];
  }
  url.search = new URLSearchParams(route.query).toString();
  return url.toString();
});

useHead(() => ({
  link: [
    {
      rel: "canonical",
      href: canonicalLink.value,
    },
  ],
}));
</script>
